import React, { useEffect, Profiler, useState } from "react";
// import ReactPWAInstallProvider, { useReactPWAInstall } from "react-pwa-install";
import "./Component-css/Downloadbutton.css?v=0.1";
// let deferredPrompt;
const Downloadbutton = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  return (
    <div
      className=" "
      // style={{
      //   position: "fixed",
      //   bottom: 0,
      //   width: "100%",
      //   maxWidth: 480,
      //   // backgroundColor: "#3A427D"
      // }}
    >
      <div className="text-center" style={{ zoom: "1.2", padding: "10px 0px" }}>
        <button
          onClick={onClick}
          className="btn btn-primary btn-sm bg-transparent"
          style={{ fontWeight: 500, backgroundColor: "#3A427D" }}
        >
          <i className="fa fa-download text-white"/>
          {/* <img
            src="https://hiplay.in/Images/android.png"
            alt=""
            style={{ marginRight: 10 }}
            width="15px"
          />
          <span
            style={{
              color: "#ffffff",
              backgroundColor: "#3A427D",
              fontWeight: "700",
            }}
          >
            {" "}
            DOWNLOAD OUR APP
          </span>

          <img
            src="https://hiplay.in/Images/dowloadIcon.png"
            alt=""
            style={{ marginLeft: 10 }}
            width="13px"
          /> */}
        </button>
      </div>
    </div>
  );
};

export default Downloadbutton;
