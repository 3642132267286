import React, { useEffect, useState } from "react";
import "../css/kyc.css";
import Rightcontainer from "../Components/Rightcontainer";
import "../css/Pan.module.css";
import "../css/Loader.css";
import axios from "axios";
import Swal from "sweetalert2";

const Manual_Payment = () => {
  // debugger;
  const backendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const backendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  const baseUrl = nodeMode === "development" ? backendLocalApiUrl : backendLiveApiUrl;

  const [amount, setAmount] = useState(0);
  const [utrNumber, setUtrNumber] = useState("");

  
  useEffect(() => {
    // debugger;
    const queryParams = new URLSearchParams(window.location.search);
    const amountParam = queryParams.get('amount');
    const utrParam = queryParams.get('utr');
    if (amountParam) {
      setAmount(amountParam);
    }
    if (utrParam) {
      setUtrNumber(utrParam);
    }
  }, []);

  // Handle copy UPI ID to clipboard
  const handleCopy = () => {
    // debugger;
    const upiId = upiSettings.upiId;
    navigator.clipboard.writeText(upiId)
      .then(() => {
        alert("UPI ID copied to clipboard!");
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  };

  
  const handleConfirm = async () => {
    // debugger;
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    };

    if (!/^\d{12}$/.test(utrNumber)) {
      Swal.fire({
        title: "Invalid UTR Number",
        text: "Please enter a valid 12-digit UTR Number",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    try {
      const response = await axios.post(
        `${baseUrl}user/manualpayment`,
        {
          upiId:upiSettings.upiId,
          amount: amount,
          urtID: utrNumber
        },
        { headers: headers }
      );

      const data = response.data;
      console.warn(data.message);
      if (data.status == "ok") {
        Swal.fire({
          title: "Payment Successfully Added",
          icon: "success",
          confirmButtonText: "OK",
        });
        window.location.reload();
      } else {
        Swal.fire({
          title: data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error confirming payment: ", error);
      alert("Error confirming payment");
    }
  };

  const [upiSettings, setUpiSettings] = useState({
    isEnabled: false,
    upiId: ""
  });
  

  useEffect(() => {
    // debugger;
    const fetchUpiSettings = async () => {
      try {
        const response = await axios.get(`${baseUrl}User/upisettings`);
        if (response.data.status === 'success') {
          const enabledSetting = response.data.upiSettings.find(setting => setting.isEnabled);
          if (enabledSetting) {
            setUpiSettings({
              isEnabled: true,
              upiId: enabledSetting.upiId
            });
          }
        } else {
          console.error("Failed to fetch UPI settings");
        }
      } catch (error) {
        console.error("Error fetching UPI settings:", error);
      }
    };
  
    fetchUpiSettings();
  }, [baseUrl]);
  
  


  return (
    <div>
      <div className="leftContainer">
        <div className="px-4 mt-5 pt-4 text-center">
          <h3>Payment Amount</h3>
          <h5 className="text-danger">₹ {amount}</h5>
          {upiSettings.isEnabled ? (
          <div className="d-flex justify-content-between align-items-center border border-1 p-2 rounded">
            <div>
              <p className="mb-0">Pay to UPI:</p>
              <p className="mb-0 text-danger">{upiSettings.upiId}</p>
            </div>
            <div className="btn btn-primary text-light py-2 px-3" onClick={handleCopy}>Copy</div>
          </div>
        ) : (
          <div className="text-danger mt-3">
            <p>UPI payments are currently disabled.</p>
          </div>
        )}
          <div className="text-left mt-3">
            <span className="text-left font-weight-bold">STEP 1:</span>
            <p className="mb-0">Click on the above button to complete payment or copy UPI ID</p>
            <span className="text-left font-weight-bold mt-2">STEP 2:</span>
            <input 
              type="text" 
              placeholder="Enter UTR Number" 
              className="rounded form-control" 
              style={{ width: "100%" }} 
              value={utrNumber}
              onChange={(e) => setUtrNumber(e.target.value)}
            />
          </div>
          <div className="btn btn-primary text-light px-5 py-3 my-4" onClick={handleConfirm}>
            <span>Confirm REF No.</span>
          </div>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};
export default Manual_Payment;
