import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2";
import axios from 'axios';
import { useHistory, useLocation } from "react-router-dom";

const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

const AboutUs = () => {
    const location = useLocation();
    const history = useHistory();
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (!location.state || !location.state.data) {
                console.error("Response data is undefined or null");
                return;
            }

            const queryParams = new URLSearchParams(location.state.data);
            const status = queryParams.get('status');
            const clientTxnId = queryParams.get('clientTxnId');
            const sabpaisaTxnId = queryParams.get('sabpaisaTxnId');
            const amount = queryParams.get('amount');

            if (!status) {
                console.error("Status is not found in the response data");
                return;
            }

            console.log(status);

            if (status === "FAILED") {
                console.log("your Response is_, -- Failed --")
                const icon = "danger";
                history.push("/landing");
                setTimeout(() => {
                    Swal.fire({
                        title: "transaction test Failed",
                        icon: icon,
                        confirmButtonText: "OK",
                    });
                }, 1000);
            } else if (status === "SUCCESS") {
                console.log("deposit upi called on success");
                try {
                    setProcessing(true);
                    const access_token = localStorage.getItem("token");
                    const headers = {
                        Authorization: `Bearer ${access_token}`,
                    };
                    const response = await axios.post(
                        baseUrl + 'user/depositeupi',
                        { amount, payment_gatway: "SabPaisa", order_id: sabpaisaTxnId, order_token: clientTxnId },
                        { headers }
                    );

                    console.log("Final response 2========>" + response);
                    console.log(response.data.status)
                    const icon = response.data.status === "PAID" ? "success" : "danger";
                    const title = response.data.status === "PAID" ? "Deposit submitted successfully" : "Transaction Failed";
                    history.push("/landing");
                    setTimeout(() => {
                        Swal.fire({
                            title: title,
                            icon: icon,
                            confirmButtonText: "OK",
                          
                        });
                         window.location.reload();
                    }, 1000);
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        window.location.reload();
                    }
                } finally {
                    setProcessing(false);
                }
            }
        };

        fetchData();
    }, []);

    if (processing) {
        return <div>Processing...</div>;
    }

    return (
        <>
            {/* Your table rendering code */}
        </>
    );
};

export default AboutUs;
